<template>
  <div class="radioBodx">
    <div class="radioTab" v-for="(item, index) in list" :key="index">
        <div
          :style="{marginLeft:index === 0?'0px':'10px'}"
          class="radio-tab-tag"
          :class="index === selectValue ? 'radio-tab-tag-ed' : ''"
          @click="radioSelect(item, index)"
        >
          {{ item.label }}
        </div>
        <div class="dividing" v-if="index != (list.length - 1)"></div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectValue: this.value,
    };
  },
  methods: {
    radioSelect(item, index) {
      this.selectValue = index;
      this.$emit("click", item, index);
    },
  },
};
</script>

<style scoped lang="scss">
.dividing {
  border: 1px solid #d8d8d8;
  height: 16px;
}

.radioBodx {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 12px 0;
  width: max-content;
}

.radioTab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  .radio-tab-tag {
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0px 10px;
    cursor: pointer;
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: justify; /* 浏览器可能不支持 */
    letter-spacing: 0px;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    /* 亮色模式/字体色/标题 */
    color: #818496;
  }

  .radio-tab-tag-ed {
    color: #21252e;
    position: relative;
  }

  .radio-tab-tag-ed:after {
    content: "";
    position: absolute;
    width: 60%;
    bottom: -40%;
    left: 20%;
    height: 5px;
    background: #e3211f;
  }
}
</style>
